'use client'

import Image from 'next/image'
import { IconCaretDownFilled } from '@tabler/icons-react'
import { useRouter, usePathname } from '@/navigation'
import { useParams } from 'next/navigation'
import { useTransition } from 'react'
import { LOCALES } from '@/i18n'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/app/_components/Reusable/Dropdown'

const LocalesDropdown = ({ footer }: { footer?: boolean }) => {
	const router = useRouter()
	const pathname = usePathname()
	const params = useParams()
	const { locale } = params

	const CURRENT_LOCALE = LOCALES[locale as keyof typeof LOCALES]

	const [isPending, startTransition] = useTransition()

	const onSelectChange = (locale: keyof typeof LOCALES) => {
		startTransition(() => {
			router.replace(pathname, { locale: locale ?? 'en' })
		})
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<button
					className={`flex gap-2 items-center text-[#24202033] font-bold outline-none ${
						footer ? 'lg:border lg:p-3 lg:px-5 lg:border-[#24202033] rounded-full' : ''
					}`}
				>
					<Image
						width={50}
						height={50}
						className='w-auto h-4'
						src={`/locales/${locale}.png`}
						alt={locale as keyof typeof LOCALES}
						// alt={LOCALES[locale as keyof typeof LOCALES]}
					/>
					{CURRENT_LOCALE} <IconCaretDownFilled size={16} />
				</button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-40'>
				<DropdownMenuGroup>
					{Object.keys(LOCALES).map((key) => (
						<DropdownMenuItem
							key={key}
							onClick={() => onSelectChange(key as keyof typeof LOCALES)}
							disabled={locale === key || isPending}
							className={`flex gap-2 ${
								locale === key ? 'bg-gradient bg-opacity-5 !opacity-100' : 'hover:bg-background'
							}`}
						>
							<Image
								width={16}
								height={16}
								src={`/locales/${key}.png`}
								alt={LOCALES[key as keyof typeof LOCALES]}
								className='w-auto h-4'
							/>
							{LOCALES[key as keyof typeof LOCALES]}
						</DropdownMenuItem>
					))}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default LocalesDropdown
