'use client'

import React from 'react'
import Intercom from '@intercom/messenger-js-sdk'

function IntercomWidget() {
	Intercom({
		app_id: 'do83n309',
	})

	return <></>
}

export default IntercomWidget
