import(/* webpackMode: "eager" */ "/app/app/_components/Base/Navbar/LocaleDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/Base/Navbar/NavbarBackground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/Reusable/IntercomWidget.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/Gilroy-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../fonts/Gilroy-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"gilroyFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Assistant\",\"arguments\":[{\"subsets\":[\"hebrew\"],\"weight\":[\"400\",\"600\",\"700\"]}],\"variableName\":\"notoSansFont\"}");
