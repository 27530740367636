'use client'

import { cn } from '@/lib/utils/cn'
import { usePathname } from 'next/navigation'
import React, { useMemo } from 'react'

function NavbarBackground() {
	const pathname = usePathname()
	const noLinks = useMemo(() => pathname?.includes('/blog'), [pathname])

	return <div className={cn('absolute top-0 left-0 w-full h-full  -z-10', { 'bg-white': noLinks })}></div>
}

export default NavbarBackground
